////
/// @group shared
/// @access public
////
@import 'functions';
@import 'colors';
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap');

@font-face {
    font-family: 'IBMPlexSans';
    src: url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap');
}

/// Injects cross-browser-compatible css for animation.
/// @param $animate - Valid animation value
/// @link http://caniuse.com/#feat=css-animation
/// @example scss - Basic Usage Sass
///     .loader {
///       @include animation(spinner 1s infinite);
///     }
/// @example scss - Basic Usage CSS Output
///     .loader  {
///       -ms-animation: spinner 1s infinite;
///       -webkit-animation: spinner 1s infinite;
///       -moz-animation: spinner 1s infinite;
///       animation: spinner 1s infinite;
///     }
@mixin animation($animate...) {
  $max: length($animate);
  $animations: '';

  @for $i from 1 through $max {
    $animations: #{$animations + nth($animate, $i)};

    @if $i < $max {
      $animations: #{$animations + ", "};
    }
  }
  @include prefixer(animation, $animations);
}

/// Changes a hex color and opacity into an rgba value.
/// Note that the fallback color will be transparent if the opacity is below 0.5
/// @param {Color} $color - A color, in hex
/// @param {Decimal} $opacity [1] - The opacity of the color from 0 to 1
/// @example scss - Basic Usage Sass
///     .overlay {
///       @include background-opacity(#e3e3e3, 0.75);
///     }
/// @example scss - Basic Usage CSS Output
///     .overlay {
///       background: #e3e3e3;
///       background: rgba(227, 227, 227, 0.75)
///     }
@mixin background-opacity($color, $opacity: 1) {
  $opacity: clamp($opacity, 0, 1);
  /* Fallback background */
  @if($opacity < 0.5) {
    background: transparent;
  } @else {
    background: $color;
  }
  background: rgba($color, $opacity);
}

/// Injects cross-browser-compatible css for border-radius.
/// @param $value - The value for the border-radius.
/// @link http://caniuse.com/#feat=border-radius
/// @example scss - Basic Usage Sass
///     .rounded-corners {
///       @include border-radius(3px);
///     }
/// @example scss - Basic Usage CSS Output
///     .rounded-corners {
///       -moz-border-radius: 3px;
///       -webkit-border-radius: 3px;
///       border-radius: 3px;
///     }
@mixin border-radius($value) {
  @include prefixer(border-radius, $value, false, false, false);
}

/// Injects cross-browser-compatible css for box-shadow.
/// @param $value - The value for the box-shadow.
/// @link http://caniuse.com/#feat=css-boxshadow
/// @example scss - Basic Usage Sass
///     .card-shadow {
///       @include box-shadow(0 1px 1px 0 #9e9e9e);
///     }
/// @example scss - Basic Usage CSS Output
///     .card-shadow {
///       -moz-box-shadow: 0 1px 1px 0 #9e9e9e;
///       -webkit-box-shadow: 0 1px 1px 0 #9e9e9e;
///       box-shadow: 0 1px 1px 0 #9e9e9e;
///     }
@mixin box-shadow($value...) {
  @include prefixer(box-shadow, #{$value});
}

/// Mixin for our default font
/// @param {String} $face ['Regular'] - The face of the font
/// @param {Boolean} $alt [false] - Whether to use the alt version of Proxima Nova. Cannot be used with $smallCaps
/// @param {Boolean} $smallCaps [false] - Whether to use the small caps version of Proxima Nova
/// @example scss - Basic Usage Sass
///     .font {
///       @include font('Black');
///     }
/// @example scss - Basic Usage CSS Output
///     .font {
///       font-family: 'ProximaNova-Black', arial, helvetica, sans-serif;
///       font-weight: 400;
///     }
@mixin font($face: 'Regular', $alt: false, $smallCaps: false) {
  $mod: '';
  @if($alt) { $mod: 'A' }
  @if($smallCaps) { $mod: 'S' }
  font-family: IBMPlexSans,'IBM Plex Sans',arial,helvetica,sans-serif!important;
  font-weight: 400 !important;
}

/// Mixin for our light font
/// @example scss - Basic Usage Sass
///     .font {
///       @include font-light();
///     }
/// @example scss - Basic Usage CSS Output
///     .font {
///       font-family: 'ProximaNova-Light', arial, helvetica, sans-serif;
///       font-weight: 400;
///     }
@mixin font-light() {
  @include font('Light');
}

/// Mixin for our regular font
/// @example scss - Basic Usage Sass
///     .font {
///       @include font-regular();
///     }
/// @example scss - Basic Usage CSS Output
///     .font {
///       font-family: 'ProximaNova-Regular', arial, helvetica, sans-serif;
///       font-weight: 400;
///     }
@mixin font-regular() {
  @include font();
}

/// Mixin for our semibold font
/// @example scss - Basic Usage Sass
///     .font {
///       @include font-semibold();
///     }
/// @example scss - Basic Usage CSS Output
///     .font {
///       font-family: 'ProximaNova-Semibold', arial, helvetica, sans-serif;
///       font-weight: 400;
///     }
@mixin font-semibold() {
  @include font('Semibold');
}

/// Mixin for SVG icons in shared
/// @example scss - Basic Usage Sass
///     .font {
///       @include font-shared();
///     }
/// @example scss - Basic Usage CSS Output
///     .font {
///       font-family: shared;
///     }
@mixin font-shared() {
  font-family: shared;
}

/// Injects cross-browser-compatible css for css3 gradients.
/// @param {Color} $start - The starting color for the gradient
/// @param {Color} $stop - The ending color for the gradient
/// @example scss - Basic Usage Sass
///     .card-shadow {
///       @include gradient(#000000, #ffffff);
///     }
/// @example scss - Basic Usage CSS Output
///     .card-shadow {
///       background: #000000; /* Old browsers */
///       background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #000000), color-stop(99%, #ffffff)); /* Chrome,Safari4+ */
///       background: -webkit-linear-gradient(top,  #000000 0%, #ffffff 99%); /* Chrome10+,Safari5.1+ */
///       background: -ms-linear-gradient(top,  #000000 0%, #ffffff 99%); /* IE10+ */
///       background: linear-gradient(to bottom,  #000000 0%, #ffffff 99%); /* W3C */
///       filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= '#000000', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
///     }
@mixin gradient($start, $stop) {
  background: $start; /* Old browsers */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $start), color-stop(99%, $stop)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  $start 0%, $stop 99%); /* Chrome10+,Safari5.1+ */
  background: -ms-linear-gradient(top,  $start 0%, $stop 99%); /* IE10+ */
  background: linear-gradient(to bottom,  $start 0%, $stop 99%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= '#{$start}', endColorstr='#{$stop}',GradientType=0 ); /* IE6-9 */
}

/// Injects cross-browser-compatible css for keyframes.
/// @param {String} $animationName
/// @example scss - Basic Usage Sass
///     @include keyframes(move) {
///       0%   { left: 100px; }
///       100% { left: 200px; }
///    }
/// @example scss - Basic Usage CSS Output
///     @-webkit-keyframes move {
///       0%   { left: 100px; }
///       100% { left: 200px; }
///     }
///     @-moz-keyframes move {
///       0%   { left: 100px; }
///       100% { left: 200px; }
///     }
///     @-ms-keyframes move {
///       0%   { left: 100px; }
///       100% { left: 200px; }
///     }
///     @keyframes move {
///       0%   { left: 100px; }
///       100% { left: 200px; }
///     }
@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-ms-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

/// Injects cross-browser-compatible css for opacity.
/// @param {Decimal} $value - The opacity value from 0 to 1
/// @example scss - Basic Usage Sass
///     .seeThrough {
///       @include opacity(0.5);
///     }
/// @example scss - Basic Usage CSS Output
///     .seeThrough  {
///       filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50); /* IE */
///       opacity: 0.5; /* CSS3 */
///     }
@mixin opacity($value) {
  $value: clamp($value, 0, 1);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=#{$value*100})"; /* IE */
  filter: alpha(opacity=$value*100); /* IE */
  opacity: $value; /* CSS3 */
}

/// Adds vendor prefixes to a given attribute.
/// @param {String} $attribute - The CSS attribute to prefix
/// @param {String} $value - The value for the given attribute
/// @param {Boolean} $webkit [true] - Whether or not to prefix for Webkit
/// @param {Boolean} $moz [true] - Whether or not to prefix for Mozilla
/// @param {Boolean} $ms [true] - Whether or not to prefix for Microsoft
/// @param {Boolean} $o [false] - Whether or not to prefix for Opera
/// @example scss - Basic Usage Sass
///     @include prefixer(box-shadow, 0);
/// @example scss - Basic Usage CSS Output
///     -webkit-box-shadow: 0;
///     -moz-box-shadow: 0;
///     -ms-box-shadow: 0;
///     box-shadow: 0;
@mixin prefixer($attribute, $value, $webkit: true, $moz: false, $ms: false, $o: false) {
  @if($webkit) {
    -webkit-#{$attribute}: $value;
  }
  @if($moz) {
    -moz-#{$attribute}: $value;
  }
  @if($ms) {
    -ms-#{$attribute}: $value;
  }
  @if($o) {
    -o-#{$attribute}: $value;
  }
  #{$attribute}: $value;
}

/// Injects cross-browser-compatible css for transform rotate.
/// @param $value - Valid rotate value
/// @example scss - Basic Usage Sass
///     .rotate {
///       @include rotate(180deg);
///     }
/// @example scss - Basic Usage CSS Output
///     .rotate  {
///       -ms-transform: rotate(180deg);
///       -webkit-transform: rotate(180deg);
///       transform: rotate(180deg);
///     }
@mixin rotate($value...) {
  @include transform("rotate", $value);
}

/// Injects necessary attribute to get text overflow working
/// @example scss - Basic Usage Sass
///     .overflow {
///       @include text-overflow();
///     }
/// @example scss - Basic Usage CSS Output
///     .overflow  {
///       white-space: nowrap;
///       overflow: hidden;
///       text-overflow: ellipsis;
///     }
@mixin text-overflow() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/// Injects cross-browser-compatible css for transform.
/// @param {String} $type - Type of transform you want to do (i.e rotate)
/// @param $value - Valid value for given type
/// @link http://caniuse.com/#feat=transforms2d
/// @link http://caniuse.com/#feat=transforms3d
/// @example scss - Basic Usage Sass
///     .rotate {
///       @include transform("rotate", 180deg);
///     }
/// @example scss - Basic Usage CSS Output
///     .rotate  {
///       -ms-transform: rotate(180deg);
///       -webkit-transform: rotate(180deg);
///       transform: rotate(180deg);
///     }
@mixin transform($type, $value) {
  @include prefixer(transform, #{$type+"("+$value+")"}, true, false, true);
}

/// Injects cross-browser-compatible css for transition.
/// @param $value - Valid value for transition
/// @link http://caniuse.com/#feat=css-transitions
/// @example scss - Basic Usage Sass
///     .transition {
///       @include transition(all .2s ease-in);
///     }
/// @example scss - Basic Usage CSS Output
///     .transition  {
///       -ms-transition: all .2s ease-in;
///       -webkit-transition: all .2s ease-in;
///       transition: all .2s ease-in;
///     }
@mixin transition($value...) {
  @include prefixer(transition, $value);
}

/// Generates a CSS triangle.
/// @param $color - Color of the triangle
/// @param $dir - Direction (up, down, left, right) the triangle faces
/// @param $size1 - Size of all the sides or primary side
/// @param $size2 [$size1] - Size of the secondary sides
/// @param $zeroDimension [true] - Whether to use a height and width of 0
/// @example scss - Basic Usage Sass
///     .triangle {
///       @include triangle(#face21, down, 120px, 60px);
///     }
/// @example scss - Basic Usage CSS Output
///     .triangle  {
///       height: 0;
///       width: 0;
///       border-left: 60px solid transparent;
///       border-right: 60px solid transparent;
///       border-top: 120px solid #face21;
///     }
@mixin triangle($color, $dir, $size1, $size2: $size1, $zeroDimension: true) {
  $primarySide: $size1 solid $color;
  $secondarySide: $size2 solid transparent;
  @if($dir == up) { $dir: bottom; }
  @else if($dir == down) { $dir: top; }
  @else if($dir == left) { $dir: right; }
  @else if($dir == right) { $dir: left; }
  @if($zeroDimension) {
    height: 0;
    width: 0;
  }
  @if($dir == bottom or $dir == top) {
    border-left: $secondarySide;
    border-right: $secondarySide;
  }
  @if($dir == left or $dir == right) {
    border-top: $secondarySide;
    border-bottom: $secondarySide;
  }
  border-#{$dir}: $primarySide;
}

/// Injects cross-browser-compatible css for user-select.
/// @param $value - Valid user-select value
/// @link http://caniuse.com/#feat=user-select-none
/// @example scss - Basic Usage Sass
///     .no-select {
///       @include user-select(none);
///     }
/// @example scss - Basic Usage CSS Output
///     .no-select  {
///       -webkit-touch-callout: none;
///       -webkit-user-select: none;
///       -khtml-user-select: none;
///       -moz-user-select: none;
///       -ms-user-select: none;
///       user-select: none;
///     }
@mixin user-select($value) {
  -webkit-touch-callout: $value;
  @include prefixer(user-select, $value, true, true, true);
}

@mixin scrollbar-dark($width: 8px) {
  /*IE Scroll */
  scrollbar-track-color: #393e40;
  scrollbar-arrow-color: #fff;
  scrollbar-face-color: #fff;
  scrollbar-hightlight-color: #fff;
  scrollbar-shadow-color: #fff;
  /* Chrome Scroll */
  &::-webkit-scrollbar {
    width: $width;
  }
  &::-webkit-scrollbar-track-piece {
    background: #71737b;
    border-radius: 25px;
  }
  &::-webkit-scrollbar-thumb {
    background: #393e40;
    border-radius: 25px;
  }
}

@mixin scrollbar-light($width: 8px) {
  /*IE Scroll */
  scrollbar-track-color: #E6E6E6;
  scrollbar-arrow-color: #9EA4A2;
  scrollbar-face-color: #9EA4A2;
  scrollbar-hightlight-color: #9EA4A2;
  scrollbar-shadow-color: #9EA4A2;
  /* Chrome Scroll */
  &::-webkit-scrollbar {
      width: $width;
  }
  &::-webkit-scrollbar-track-piece {
      background: #E6E6E6;
      border-radius: 0;
  }
  &::-webkit-scrollbar-thumb {
      background: #9EA4A2;
      border-radius: 0;
  }
}
