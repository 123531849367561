@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';
@import 'spritePositions';

/* \/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\ */
/* IMPORTANT: Please do not makes changes to this document without consulting the UX team. */
/* contact: ux[at]allegiance[dot]com */
/* Visit http://static.allegiancetech.com/UI/ for documentation */
/* \/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\ */



body {
	@include font-regular();
	font-size: 13px;
	color: $color-font-default;
	background-color: #fff;
	text-align: left;
	min-width: 768px;
}
body#loginBannerBody {
	margin-top: 8px!important;
}
/*
	There are cases where an iFrame injects a file for a dialog box.
	There is no need to enforce a minimum width on these dialog
	boxes. Artifacts resulting from min-width are overflows and
	horizontal scrollbars.
*/
body.modalDialog {
	min-width: 0;
}

em, .italic {
	font-style: italic !important;
}

strong, .bold, label.topLabel {
	font-weight: 700 !important;
}

.underline {
	text-decoration: underline !important;
}

.floatRight {
	float: right !important;
}

.floatLeft {
	float: left !important;
}

.floatNone {
	float: none !important;
}

.displayBlock, label.topLabel {
	display: block !important;
}

.displayNone, .hidden {
	display: none !important
}

input::-ms-clear {
	display: none !important;
}

.positionRelative {
	position: relative !important;
}

.marginCenter {
	margin: 0 auto !important;
}

.allegBorder {
	border: 1px solid #ccc;
}

.noTopBorder {
	border-top: 0 !important;
}

.noLeftBorder {
	border-left: 0 !important;
}

.noRightBorder {
	border-right: 0 !important;
}

.noBottomBorder {
	border-bottom: 0 !important;
}

.highlight {
	background-color: #ff0;
}

.caption {
	font-size: 10px;
}

.m0 {
	margin: 0 !important;
}

.m5 {
	margin: 5px !important;
}

.ml5 {
	margin-left: 5px !important;
}

.mr5 {
	margin-right: 5px !important;
}

.mt5 {
	margin-top: 5px !important;
}

.mb5 {
	margin-bottom: 5px !important;
}

.m10 {
	margin: 10px !important;
}

.ml10 {
	margin-left: 10px !important;
}

.mr10 {
	margin-right: 10px !important;
}

.mt10 {
	margin-top: 10px !important;
}

.mb10 {
	margin-bottom: 10px !important;
}

.p0 {
	padding: 0 !important;
}

input[type=text].dark {
	background-color: #fff;
	border: 1px solid #6d7072;
	border-radius: 25px!important;
	color: #253746;
	height: 25px;
	padding-left: 8px;
}

/*TODO: Add padding and margin rules that will be based off design guidelines. Ex. .p10 {padding:10px;} .m5-10 {margin:5px 10px;}*/

.allegInfoboxTooltip,
.allegInfobox {
	background-color: #f1f1f1;
	background-image: url('Images/infoOrange16.png');
	background-position: 5px 5px;
	background-repeat: no-repeat;
	border: 1px solid #ccc;
	border-radius: 3px;
	font-size: 10px;
	line-height: 15px;
	margin: 5px;
	padding: 5px 10px 5px 28px;
	min-width: 300px;
}

.allegInfoboxTooltip h3,
.allegInfobox h3 {
	@include font-semibold();
	font-size: 13px;
	padding: 0 0 5px;
	margin-top: 0;
	margin-bottom: 0;
	border: none;
}

.allegInfobox.big {
	width: auto;
	font-size: 12px;
}

.mcxInfobox {
	font-size: 13px;
	margin: 5px;
	padding: 5px 10px 5px 28px;
}

.iconshow {
	background: url(Images/Sprites/info.svg) no-repeat;
	color: transparent;
	padding-left: 25px;
	font-size: 20px;
}

.slick-footerrow .mcxInfobox {
	margin: 0;
	line-height: 42px;
	background: #ebebeb;
	color: #253746;
	padding-left: 35px;
	padding-top: 1px;
}

.allegWarningGray {
	font-style: italic;
	line-height: 12px;
	font-size: 11px;
}

.allegWarningGray > div.icon_sprites {
	margin-right: 3px;
	vertical-align: top;
}

.validationError,
input.error {
	border: 1px solid #cf3b28 !important;
}

.validationMsg,
label.error {
	display: inline-block;
	margin-left: 3px;
	color: #cf3b28;
	height: 18px;
	padding-left: 23px;
	background: url('Images/error.png') no-repeat left center;
	text-align: left;
	font-weight: 400;
}

.cacheDataOverlay {
	display: none;
	@include font-semibold();
	text-align: center;
	min-height: 102px !important;
}

hr.allegHr {
	border: 1px solid #aaa;
	border-bottom: 0;
}

a {
	color: $color-font-green-mcx;
	text-decoration: none;
	transition: color 0.15s linear;
}

a:hover {
	cursor: pointer;
	text-decoration: none;
}

a.dark {
	color: #0058FF;
	text-decoration: none;
	transition: color 0.15s linear;
}

a.dark:hover {
	color: #0058FF;
	cursor: pointer;
	text-decoration: none;
}

.link {
	color: #0058FF;
	text-decoration: none;
	@include transition(color 0.15s linear);
}

.link:hover {
	cursor: pointer;
	color: $color-font-green-mcx;
	text-decoration: underline;
}

.link.big {
	font-size: 15px;
}

.link.disabled {
	color: #808080;
	cursor: default;
}

.greenLink {
	color: #8cc652;
	display: inline-block;
}

.altGreenLink {
	color: $color-font-green-mcx;
	display: inline-block;

	@include font-semibold();

	&:hover {
		color: $color-font-green-dark;
		cursor: pointer !important;

		@include transition(color 02s ease);
	}
}

.spaced,
.spaced li {
	line-height: 16px;
}

.ellipsis {
	white-space: nowrap;
	width: 80%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
}

.cancelSort {
	cursor: default;
}

/* Messages styles */

.allegianceMessageRegion {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10002;
}

.reportingMessage {
	width: 100%;
	box-shadow: 0 10px 10px -4px #aaa;
	min-height: 34px;
	display: none;
	clear: both;
}

.reportingMessageHeader {
	overflow: auto;
}

.reportingSpanMessage {
	@include font-regular();
	font-size: 12px;
	margin: 10px 0;
	white-space: pre-wrap;
	word-wrap: break-word;
}

.reportingMessageIcon {
	padding: 6px 3px;
}

.reportingMessageClose {
	float: right;
	margin: 10px 5px 3px 3px;
	cursor: pointer;
}

div.reportingMessage img {
	border: none;
}

.infoBox {
	background: $color-background-charcoal;
	border-radius: 5px;
	box-sizing: border-box;
	font-size: 14px;
}

.infoBox > p {
	color: #fff;
	margin: 0;
	padding: 10px;
}

.infoBox > h3 {
	background: #8cc652;
	@include border-radius(5px 5px 0 0);
	box-sizing: border-box;
	color: #fff;
	font-size: 16px;
	height: 27px;
	line-height: 27px;
	margin: 0;
	padding-left: 10px;
}

.reportingReportRegion.t-flatWhite .cardShadow:hover,
.cardShadow {
	@include box-shadow(0 0 1px rgba(#000,0.1), 0 2px 1px rgba(#000,0.1));
}

.cardShadowStrong {
	@include box-shadow(0 0 2px rgba(#000,.12),0 2px 4px rgba(#000,.24));
}

.listItemToken {
	background: #fff;
	@include border-radius(2px);
	@include box-shadow(0 0 1px rgba(#000,0.1), 0 2px 1px rgba(#000,0.1));
	box-sizing: border-box;
	height: 34px;
	line-height: 34px;
	margin: 8px;
	position: relative;
}

.listItemToken.c-indent {
	margin-left: 16px;
}

.listItemToken.disabled {
	@include box-shadow(none);
	background: #e8e8e8;
	color: $color-font-medium;
}

.listItemToken.disabled .listItemTokenHandle,
.listItemToken.disabled .listItemTokenText {
	display: none;
}

.listItemTokenHandle {
    background: #0058FF;
	@include border-radius(7px 0 0 7px);
	cursor: move;
	display: inline-block;
	height: 100%;
	width: 14px;
	
}

.listItemTokenHandle > div {
	cursor: move;
	margin-left: 5px;
}

.listItemTokenDisabledText,
.listItemTokenText {
	display: inline-block;
	margin-left: -3px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.listItemColumnsWrapper {
	position: absolute;
	top: 0;
	display: inline-block;
	width: 100%;
	left: 0;
	padding-left: 15px;
	box-sizing: border-box;
}

.listItemTokenText {
	padding-left: 15px;
	vertical-align: top;
	box-sizing: border-box;
}

.listItemTokenText .icon_sprites {
	margin: 8px 0 10px 5px;
	cursor: default;
}

.listItemToken.disabled .icon_sprites.listItemTokenDisabledIcon {
	margin: 8px 8px 10px 5px;
	cursor: default;
}

.listItemToken.disabled .listItemTokenDisabledText,
.listItemToken.disabled .icon_sprites.listItemTokenDisabledIcon {
	max-width: 285px;
	display: inline-block;
	vertical-align: middle;
}

.listItemTokenDisabledText {
	margin-left: 8px;
}

.listItemTokenDisabledText,
.icon_sprites.listItemTokenDisabledIcon {
	display: none;
}

@media print {
	.noPrint {
		display: none;
	}
}

/* case management ui */

body.t-mcx,
/* needed to override browser stylesheets */
body.t-mcx input,
body.t-mcx textarea,
body.t-mcx select,
body.t-mcx button {
	@include font-regular();
}

.t-mcx hr {
	border-top: 1px solid #ccc;
}

body.t-mcx strong,
body.t-mcx b,
body.t-mcx .bold,
body.t-mcx label.topLabel {
	@include font-semibold();
}

body.t-mcx input[type=checkbox] + label {
	font-weight: normal;
}

body.t-mcx input,
body.t-mcx select,
body.t-mcx textarea {
	border: 1px solid #606060;
	@include border-radius(26px);
	font-size: 13px;
	box-sizing: border-box;
	padding-left: 8px;
	padding-bottom: 2px;
}

textarea#txtNewActivityNote {
	border-radius: 20px !important;
}

body.t-mcx textarea {
	height: auto;
}

body.t-mcx input[type=checkbox],
body.t-mcx input[type=radio] {
	border: none;
	height: 21px;
	vertical-align: middle;
}

body.t-mcx table.cke_dialog td {
	border: 0;
}

.t-mcx .validationError,
.t-mcx input.error {
	border: 1px solid $RED !important;
}

.t-mcx .validationMsg,
.t-mcx label.error {
	color: $RED;
	padding-left: 0;
	background: none;
}

.t-mcx .validationError + .validationMsg,
.t-mcx input.error + label.error {
	display: block;
}


body.t-mcx .cacheDataOverlay {
	@include font-semibold();
}

#displayFilter {
	background: white !important;
	border-radius: 12px !important;
	display: none;
}

.jumpto {
	background: white !important;
	border-radius: 12px !important;
}

#txtSearchSurvey {
	background: white !important;
	border-radius: 12px !important;
	text-transform: capitalize !important;
	padding-left: 10px;
	width: 140px;
}

#pageSize {
	background: white !important;
	border-radius: 12px !important;
}
body.t-mcx input_New, body.t-mcx select_New, body.t-mcx textarea_New {
	border: 1px solid #606060;
	border-radius: 12px;
	font-size: 13px;
	padding: 3px;
	box-sizing: border-box;
	height: 26px;
	margin: 2px;
	font-family: "IBM Plex Sans", Regular;
	color: #ffff;
}

.allegSearchWrapper .allegSearchIcon {
	background-image: url(Images/svg/search.svg);
	background-repeat: no-repeat;
}

.allegSearchWrapper .allegSearchClear {
	background-image: url(Images/svg/cancel.svg);
	background-repeat: no-repeat;
}