@import 'colors';
@import 'variables';
@import 'functions';
@import 'mixins';

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
						/* Buttons */ 
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */


button.ui-state-default.primaryBtn,
button.ui-state-default.secondaryBtn,
button.ui-state-default.primaryBtn:hover,
button.ui-state-default.secondaryBtn:hover,
.listActions .primaryBtn,
.manageActions .primaryBtn,
.primaryBtn,
.secondaryBtn,
.blueBtn:hover,
.grayBtn:hover,
.primaryBtn:hover,
.secondaryBtn:hover
{
	border:0;
	box-sizing:border-box;
	cursor:pointer;
	display: inline;
	filter: none;
	float:none;
	@include font-regular();
	font-size: 14px !important;
	height:25px;
	line-height:9px;
	margin: 3px;
	min-width: 90px;
	padding: 2px 7px;
	text-align: center !important;
	text-decoration:none;
	/*text-transform: uppercase;*/
}
.primaryBtn,
.secondaryBtn
{
	transition: background .25s linear;
}
.primaryBtn:active {
	transition: background 0s ease-out;
	background: #490 !important;
}
.secondaryBtn:active {
	transition: background 0s ease-out;
	background: #aaa !important;
}
.primaryBtn .ui-button-text,
.secondaryBtn .ui-button-text {
	padding: 0;
}

.primaryBtn.big,
.secondaryBtn.big,
.allegDropdownMenu .secondaryBtn ,
.allegDropdownMenu:hover .secondaryBtn ,
.allegDropdownMenu .primaryBtn,
.allegDropdownMenu:hover .primaryBtn
{
	padding:2px 10px;
	font-size:14px;
	font-weight:400;
	line-height:0;
}

.primaryBtn.medium,
.secondaryBtn.medium 
{
	padding:13px 8px;
	font-size:13px;
	font-weight:400;
	line-height:0;
}

.primaryBtn.small,
.secondaryBtn.small {
	font-size: 12px;
	height: 23px;
	min-width: 58px;
}

button.ui-state-default.primaryBtn.disabled,
button.ui-state-default.primaryBtn.disabled:hover,
.primaryBtn.disabled,
.primaryBtn.disabled:hover
{
	background: #bebebe;
	border-color:#bebebe;
	color: $color-font-charcoal;
	cursor:default;
	@include opacity(0.5);
}

button.ui-state-default.secondaryBtn.disabled,
button.ui-state-default.secondaryBtn.disabled:hover,
.secondaryBtn.disabled,
.secondaryBtn.disabled:hover 
{
	cursor:default;
	color:#858585;
	background: #f7f7f7;
	border-color:#e0e0e0;
}

/*body .ui-dialog .ui-state-default, .ui-dialog.ui-widget-content .ui-state-default, .ui-dialog .ui-widget-header .ui-state-default,*/
body button.ui-state-default.primaryBtn,
.PopUpFooter button.ui-state-default.primaryBtn,
button.ui-state-default.primaryBtn,
.primaryBtn {
	color: #fff !important;
	background: #253746 !important;
	border: 0;
	border-radius: 20px;
	text-transform: capitalize;
	padding: 0 16px !important;
}

button.ui-state-default.primaryBtn:hover,
.primaryBtn:hover,
.allegDropdownMenu:hover .primaryBtn
{
	color: #fff;
	background: #253746 !important;
	opacity: 0.8;
	border-radius: 20px;
}

button.ui-state-default.secondaryBtn,
.secondaryBtn {
	color: #253746 !important;
	background: #ffffff !important;
	border-radius: 20px;
	border: 1px solid #253746 !important;
	text-transform: capitalize !important;
	padding: 0px 16px 0 16px !important;
	min-width: 90px;
}

button.ui-state-default.secondaryBtn:hover,
.secondaryBtn:hover,
.allegDropdownMenu:hover .secondaryBtn
{
	color: #253746!important;
	background: #ffffff!important;
	border: 1px solid #253746!important;
	border-radius: 20px;
	opacity: 0.8;
}

.allegDropdownMenu .arrow-down,
.allegDropdownMenu .arrow-right 
{
	margin-top:-2px;
}

#loginPaneWrapper {
	.primaryBtn,
	.secondaryBtn,
	.primaryBtn:hover,
	.secondaryBtn:hover {
		border: 0;
		border-radius: 2px;
		box-sizing: border-box;
		cursor: pointer;
		display: inline;
		filter: none;
		float: none;
		font-size: 14px;
		font-weight: 400;
		height: 25px;
		line-height: 8px;
		margin: 3px;
		min-width: 116px;
		padding: 2px 7px;
		text-align: center !important;
		text-transform: uppercase;
	}
}

#caseViewRegion {
	.primaryBtn,
	.secondaryBtn {
		@include font-semibold();
	}
}
body.t-mcx {
	.primaryBtn.big,
	.secondaryBtn.big,
	.allegDropdownMenu .secondaryBtn ,
	.allegDropdownMenu:hover .secondaryBtn ,
	.allegDropdownMenu .primaryBtn,
	.allegDropdownMenu:hover .primaryBtn {
		padding:2px 10px;
		font-size:14px;
		font-weight:400;
		line-height:0;
	}

	.primaryBtn.medium,
	.secondaryBtn.medium
	{
		padding:13px 8px;
		font-size:13px;
		font-weight:400;
		line-height:0;
	}

	.primaryBtn.disabled,
	.primaryBtn.disabled:hover
	{
		color: $color-font-charcoal;
		cursor:default;
		@include opacity(0.5);
	}

	.secondaryBtn.disabled,
	.secondaryBtn.disabled:hover
	{
		cursor:default;
		color:#858585;
		background: #f7f7f7;
		border-color:#e0e0e0;
	}
}

#loginPaneWrapper .primaryBtn.small,
#loginPaneWrapper .secondaryBtn.small,
body.t-mcx .primaryBtn.small,
body.t-mcx .secondaryBtn.small {
	font-size: 12px;
	height: 23px;
	min-width: 58px;
}

#loginPaneWrapper .primaryBtn,
body.t-mcx .primaryBtn {
	color: #FFFFFF;
	background: #8cc652;
	border-radius: 20px;
}

#loginPaneWrapper  .primaryBtn:hover,
body.t-mcx .primaryBtn:hover,
body.t-mcx .allegDropdownMenu:hover .primaryBtn
{
	color: #fff;
	background: #8cc652;
	@include opacity(0.8);
}

#loginPaneWrapper .primaryBtn:hover,
body.t-mcx .primaryBtn:hover, .secondaryBtn:hover {
	border-radius: 20px;
	text-transform: capitalize;
}

#loginPaneWrapper  .secondaryBtn,
body.t-mcx .secondaryBtn {
	color: #253746;
	background: #cfcfcf;
	border: 0;
}

#loginPaneWrapper  .secondaryBtn:hover,
body.t-mcx .secondaryBtn:hover,
body.t-mcx .allegDropdownMenu:hover .secondaryBtn
{
	color: $color-font-default;
	background: #ccc;
	@include opacity(0.8);
}
body.t-mcx .secondaryBtn:hover{
	background:#F4F5F6!important;
	opacity:1;
}
#caseViewRegion {
	.primaryBtn:hover,
	.secondaryBtn:hover {
		font-size: 13px;
	}
}


/* Overrides for old buttons that have span inside of the that have additional styles on them. */
body.t-mcx button.primaryBtn > span,
body.t-mcx button.secondaryBtn > span {
	height:auto;
	background:none;
	background-color:inherit;
	padding:0;
	font-size: inherit;
}
/*Update colors with new red*/
body.t-mcx button.redBtn{
  background: #e87f7b  !important;
  color: #494d1f ;
}
#MoreActionsOptionsTools_New {
	border: 1px solid #253746 !important;
	background: #fff !important;
	border-radius: 12px !important;
	padding-right: 5px !important;
}

body.t-mcx button_New {
	font-family: IBM Plex Sans, Regular;
	font-weight: 400 !important;
	color: #253746;
}

body.t-mcx .secondaryBtn_New {
	border: 1px solid #253746 !important;
	background: #fff !important;
	border-radius: 12px !important;
	padding-right: 5px !important;
}